<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-api"
            title="API Catalog"
            inline
        >
          <template v-if="!services">
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" small elevation="3" class="ml-2" @click="initialize">
                <v-icon small color="white">mdi-refresh</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-title>
          </template>
          <template v-else-if="services && services.length === 0">
            <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
              <h2>Oops!</h2>
              <p>
                The API catalog appears to be empty because you have no M360API Gateway deployed and running in this
                environment.<br/>
                Please deploy the M360API Gateway in this environment then click on refresh.
              </p>
              <p>
                You can download the M360API Gateway from the <b>M360API Portal</b>.<br/>
                Log in to your account, open your project, download and extract the Gateway,
                and follow the instructions in the <b>README.md</b> file.
              </p>
              <v-btn color="success" class="mx-auto float-left mr-2" small elevation="3" @click="logintoPortal">
                <v-icon small color="white" class="mr-1">mdi-lock</v-icon>
                Log in to M360API Portal
              </v-btn>
              <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                Read the Docks
              </v-btn>
              <v-btn v-if="$store.getters[ 'project/get' ] && $store.getters[ 'project/get' ] !==''" color="error"
                     class="mx-auto float-left ml-2" small elevation="3" @click="openDocumentation2">
                <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                M360Gateway for Local Development
              </v-btn>
              <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="initialize">
                <v-icon small color="white" class="mr-1">mdi-refresh</v-icon>
                Refresh
              </v-btn>
            </v-alert>
            <v-divider class="mb-3"></v-divider>

          </template>
          <v-row no-gutters v-else>
            <v-col cols="12">
              <template v-if="groups">
                <v-card-title class="float-left">
                  <v-btn color="primary" small elevation="3" class="ml-2" @click="initialize">
                    <v-icon small color="white">mdi-refresh</v-icon>
                  </v-btn>
                </v-card-title>
                <v-tabs right>
                  <v-tab v-for="oneGroup in groups" :key="oneGroup" @click="defaultGroup = oneGroup">{{ oneGroup }}
                  </v-tab>
                </v-tabs>
              </template>
              <template v-for="(serviceInfo, serviceName) in services">
                <v-col
                    cols="12" md="12" lg="12"
                    :key="serviceName"
                    class="d-flex mt-5"
                    style="flex-direction:column"
                    v-if="serviceInfo.group === defaultGroup"
                >
                  <v-hover
                      v-slot:default="{ hover }"
                      open-delay="50"
                  >
                    <v-card
                        class="ma-2 pa-2 flex-grow-1"
                        outlined
                        :style="{ 'background-color': '#f4f4f4','position':'relative' }"
                        hover
                    >
                      <v-card-title class="display-2 mb-2" :color="hover? 'primary--text' : ''">{{
                          serviceName
                        }}
                      </v-card-title>

                      <v-tabs vertical>
                        <template v-for="(versionInfo, version) in serviceInfo">
                          <template v-if="version !=='latest' && version !== 'group'">
                            <v-tab :key="`tab_${version}`" class="text-left"
                                   :color="(!versionInfo.healthy) ? 'error--text': 'success--text'"
                            >
                              <v-icon left :color="(!versionInfo.healthy) ? 'error': 'success'">mdi-tag</v-icon>
                              {{ version }}
                            </v-tab>
                            <v-tab-item :key="`tab_item_${version}`">

                              <v-row>
                                <v-col cols="6">
                                  <v-chip class="ml-1" color="secondary lighten-1" label>Main &raquo;
                                    {{ versionInfo.ports.data }}
                                  </v-chip>
                                  <v-chip class="ml-3" color="warning lighten-1" label>Maintenance &raquo;
                                    {{ versionInfo.ports.maintenance }}
                                  </v-chip>
                                  <v-chip v-if="serviceName !=='gateway' " class="ml-3" color="error lighten-1" label>

                                    <v-edit-dialog
                                        v-if="wrr[serviceName]"
                                        @save="enableUpdate(serviceName, version)"
                                        :return-value.sync="versionInfo.weight"
                                    >
                                      Weight &raquo; {{ versionInfo.weight }}
                                      <template v-slot:input>
                                        <v-text-field
                                            type="number"
                                            v-model="versionInfo.weight"
                                            :rules="[versionInfo.weight > 0]"
                                            label="Edit"
                                            single-line
                                            counter
                                        ></v-text-field>
                                      </template>
                                    </v-edit-dialog>
                                    <template v-else>
                                      Weight &raquo; {{ versionInfo.weight }}
                                    </template>
                                  </v-chip>

                                  <v-chip label
                                          v-if="canAccess({route: `/environments/:env/api-catalog/service`, method: 'post'})"
                                          v-show="versionInfo.updateService"
                                          :ref="`update-service-${serviceName}-${version}`"
                                          class="ml-3"
                                          @click="doUpdateService(versionInfo, $event)"
                                          :color="versionInfo.blink"
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            class="mr-1"
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                          mdi-content-save
                                        </v-icon>
                                        Update
                                      </template>
                                      <span>Update Service & Host Weight Values</span>
                                    </v-tooltip>
                                  </v-chip>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          class="mr-2"
                                          :color="hover? 'info': 'grey'"
                                          @click="openApiDrawer(versionInfo)"
                                          v-bind="attrs"
                                          v-on="on"
                                      >mdi-information
                                      </v-icon>
                                    </template>
                                    <span>API Information</span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          class="mr-2 button blinkable"
                                          @click="maintenanceOp('heartbeat',serviceName, versionInfo.version.value, versionInfo.heartbeat, $event)"
                                          v-bind="attrs"
                                          v-on="on"
                                      >mdi-heart
                                      </v-icon>
                                    </template>
                                    <span>Heartbeat</span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          class="mr-2 button blinkable"
                                          @click="maintenanceOp('reloadRegistry', serviceName, versionInfo.version.value, versionInfo.registry, $event)"
                                          v-bind="attrs"
                                          v-on="on"
                                      >mdi-refresh
                                      </v-icon>
                                    </template>
                                    <span>Reload Registry</span>
                                  </v-tooltip>

                                  <v-menu v-if="Object.keys( versionInfo.apis.maintenance ).length!==0" bottom left
                                          offset-y>
                                    <template
                                        v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          class="no-bgBtn"
                                          v-bind="attrs"
                                          v-on="on"
                                          icon
                                      >
                                        <v-tooltip bottom>
                                          <template
                                              v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                class="mr-2"
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on">mdi-tools
                                            </v-icon>
                                          </template>
                                          <span>Maintenance Operations</span>
                                        </v-tooltip>
                                      </v-btn>
                                    </template>
                                    <v-list right dense>
                                      <v-list-item-group v-for="(apis, method) in versionInfo.apis.maintenance"
                                                         :key="method">

                                        <div class="text-center" v-if="method.toLowerCase() === 'get'">
                                          <v-list-item
                                              v-for="(oneAPI, route) in apis"
                                              :key="route"
                                              @click="maintenanceOpC('custom', oneAPI, method, route, serviceName, versionInfo.version.value)"
                                          >

                                            <v-list-item-icon>
                                              <v-icon v-text="oneAPI.icon|| 'mdi-tools'"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title class="text-right"
                                                                 v-text="oneAPI.label || route"></v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-dialog
                                              v-model="dialog.show"
                                              :persistent="dialog.show"
                                              :width="dialog.width || 500"
                                          >
                                            {{ dialog.show }}
                                            <v-card>
                                              <v-card-title class="headline pb-5">
                                                {{ dialog.header }}
                                              </v-card-title>

                                              <v-skeleton-loader
                                                  v-if="dialog.loading"
                                                  :loading="dialog.loading"
                                                  :transition="transition"
                                                  height="94"
                                                  type="list-item-three-line"
                                                  dark
                                              >
                                              </v-skeleton-loader>
                                              <template v-else>
                                                <v-card-text>
                                                  <json-viewer
                                                      v-if="typeof dialog.body === 'object'"
                                                      class="my-4"
                                                      :value="dialog.body"
                                                      :expand-depth="2"
                                                      theme="my-awesome-json-theme"
                                                      boxed
                                                      expanded
                                                      :copyable="true"
                                                      @copied="copyToMyClipboard"
                                                  ></json-viewer>
                                                  <div v-else>
                                                    <v-banner
                                                        elevation="23"
                                                    >{{ dialog.body }}
                                                    </v-banner>
                                                  </div>
                                                </v-card-text>

                                                <v-divider></v-divider>
                                              </template>
                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="error lighten-1"
                                                    @click="dialog.show = false"
                                                >
                                                  Close
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                              </v-card-actions>
                                            </v-card>
                                          </v-dialog>
                                        </div>
                                      </v-list-item-group>
                                    </v-list>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-data-table
                                  disable-pagination
                                  disable-sort
                                  disable-filtering
                                  hide-default-footer
                                  :headers="(serviceName === 'gateway') ? gateway_headers : headers"
                                  :items="versionInfo.host"
                                  class="elevation-1 ma-1"
                              >
                                <template v-slot:item.lastCheck="{ item }">
                                  {{ new Date(item.lastCheck || fakeDate).toISOString() }}
                                </template>

                                <template v-slot:item.healthy="{ item }">
                                  <v-icon
                                      class="mr-2"
                                      small
                                      :color="item.healthy ? 'success': 'error'"
                                  >
                                    {{ item.healthy ? 'mdi-check-bold' : 'mdi-close-circle' }}
                                  </v-icon>

                                  <v-tooltip v-if="versionInfo.stats" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          color="orange"
                                          @click="openAnalytics(serviceName, version, versionInfo)"
                                      >
                                        mdi-chart-bar-stacked
                                      </v-icon>
                                    </template>
                                    <span>Open Analytics</span>
                                  </v-tooltip>
                                </template>

                                <template v-if="serviceName !== 'gateway'" v-slot:item.weight="props">
                                  <v-edit-dialog
                                      @save="enableUpdate(serviceName, version)"
                                      v-if="versionInfo.host.length > 1 "
                                      :return-value.sync="props.item.weight"
                                  >
                                    {{ props.item.weight }}
                                    <template v-slot:input>
                                      <v-text-field
                                          type="number"
                                          v-model="props.item.weight"
                                          :rules="[props.item.weight > 0]"
                                          label="Edit"
                                          single-line
                                          counter
                                      ></v-text-field>
                                    </template>
                                  </v-edit-dialog>
                                  <template v-else>
                                    {{ props.item.weight }}
                                  </template>
                                </template>
                              </v-data-table>
                            </v-tab-item>
                          </template>
                        </template>
                      </v-tabs>
                    </v-card>
                  </v-hover>
                </v-col>
              </template>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <div id="maintenance_api_response" :style="{'display':'none'}"></div>
    </v-row>

    <v-dialog v-model="bottomDrawer" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary mb-2">
          <v-btn icon dark @click="closeDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <template v-if="drawerInfo && drawerInfo.name && drawerInfo.version">
              {{ drawerInfo.name }} / v{{ drawerInfo.version.value }}
            </template>
            <template v-else>
              {{ drawertitle }}
            </template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template v-if="drawerInfo">
          <v-row class="mx-5" v-if="drawerInfo.swagger && drawerInfo.swagger.trim() !==''">
            <v-col cols="12">
              <iframe
                  :src="drawerInfo.swagger"
                  :height="drawerInfo.iframHeight || 600"
                  style="width:100%" frameborder="0"
              ></iframe>
            </v-col>
          </v-row>
          <v-row class="mx-5" v-else-if="drawerInfo.apis && drawerInfo.apis.main">
            <v-col cols="12">
              <v-tabs v-model=activeTab>
                <template v-for="(apis, method) in drawerInfo.apis.main">
                  <v-tab @change="updateTabMethod(method)" :key="'tab_'+method">{{ method }}</v-tab>
                  <v-tab-item :key="method">
                    <v-data-table
                        class="mt-3"
                        :items="formatUrls(apis)"
                        :headers="urlsTableHeaders"
                        disable-sort
                        disable-pagination
                        disable-filtering
                        :items-per-page="urlItemsPerPage(apis)"
                        hide-default-footer
                        @click:row="rowClick"
                        item-key="url"
                        single-select
                        v-model="selectedUrlRow"
                    >

                      <template v-slot:item.info.access="{ item }">
                        <v-icon
                            class="mr-2"
                            small
                            :color="item.info.access ? 'success': 'error'"
                        >
                          {{ item.info.access ? 'mdi-check-bold' : 'mdi-close-circle' }}
                        </v-icon>
                      </template>

                    </v-data-table>
                  </v-tab-item>
                </template>
              </v-tabs>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar.show"
        :bottom="snackbar.y === 'bottom'"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :right="snackbar.x"
        :timeout="snackbar.timeout"
        :top="snackbar.y === 'right'"
        :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'api',

  data() {
    return {
      loading: true,
      transition: 'scale-transition',
      title: {
        apiLogs: 'API Logs',
        apiInformation: 'API Information'
      },
      selectedUrlRow: [],
      urlsTableHeaders: [
        {text: 'Private', value: 'info.access'},
        {text: 'Name', value: 'info.label'},
        {text: 'Route', value: 'url'},
      ],
      activeTab: null,
      firstTabIndex: 0,
      dialog: {
        show: false,
        loading: false,
        header: "Response Message",
        body: [],
      },
      snackbar: {
        show: false,
        text: 'Hello, I\'m a snackbar',
        timeout: 6000,
        x: 'right',
        y: 'right',
        mode: '',
        color: 'success',
      },
      blinkTimeout: 600,
      operation: {
        heartbeat: {}
      },
      refDrawer: 'swipeableBottomSheet',
      bottomDrawer: false,
      drawertitle: null,
      drawerInfo: null,
      groups: null,
      defaultGroup: null,
      headers: [
        {text: 'Healthy', value: 'healthy'},
        {text: 'Host', align: 'start', value: 'ip'},
        {text: 'Hostname', value: 'hostname'},
        {text: 'Last Check', value: 'lastCheck'},
        {text: 'Weight', value: 'weight'},
      ],
      gateway_headers: [
        {text: 'Healthy', value: 'healthy'},
        {text: 'Host', align: 'start', value: 'ip'},
        {text: 'Hostname', value: 'hostname'},
        {text: 'Last Check', value: 'lastCheck'}
      ],
      services: null,
      fakeDate: 1597334021723,
      wrr: null
    }
  },

  mixins: [globalMixins],

  components: {
    JsonViewer
  },

  props: {
    envSelected: {
      type: Object
    }
  },

  methods: {

    logintoPortal() {
      let saasLink = 'https://portal.m360api.io/';
      window.open(saasLink);
    },

    openDocumentation() {
      window.open(this.$helpLinks.gwInstall);
    },

    openDocumentation2() {
      window.open(this.$helpLinks.gwLocal);
    },

    async initialize() {
      this.services = null;

      const services = await this.getSendData({
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/api-catalog`,
        method: "get",
      });

      this.services = services.item;
      this.wrr = {};
      if (!this.services) {
        this.services = [];
      }

      this.groups = [];

      for (let serviceName in this.services) {

        this.wrr[serviceName] = false;
        Object.keys(this.services[serviceName]).forEach((key) => {
          if (key.includes('v')) {
            this.wrr[serviceName] = true;
          }
        });

        for (let version in this.services[serviceName]) {
          if (version !== 'latest' && version !== 'group') {
            if (!this.groups.includes(this.services[serviceName][version].group)) {
              this.groups.push(this.services[serviceName][version].group);
            }

            if (!this.services[serviceName].group) {
              this.services[serviceName].group = this.services[serviceName][version].group;
            }
            let hosts = [];
            for (let host in this.services[serviceName][version].host) {
              hosts.push(this.services[serviceName][version].host[host]);
            }

            this.services[serviceName][version].updateService = false;
            this.services[serviceName][version].blink = 'grey';
            this.services[serviceName][version].host = hosts;
          }

          if (serviceName === 'gateway') {
            if (this.services[serviceName][version].apis && this.services[serviceName][version].apis.maintenance &&
                this.services[serviceName][version].apis.maintenance.get) {
              delete this.services[serviceName][version].apis.maintenance.get['/heartbeat'];

            }
          }

        }
      }

      if (this.groups.length > 0) {
        this.defaultGroup = this.groups[0];
      }

      if (this.services && this.services.length > 0) {
        this.controlDrawer(this.services)
      }
    },

    getVersion(v) {
      return 'v' + v
    },

    controlDrawer(services) {
      const {service, v} = this.$route.query
      const selectedService = services[service]
      if (service && v && !this.bottomDrawer && selectedService) {
        this.openApiDrawer(selectedService[this.getVersion(v)])
      }
    },

    updateTabMethod(method) {
      if (this.$route.query.method !== method) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            method: method
          }
        })
      }
    },

    openApiDrawer(data) {
      this.startDrawer(data, this.title.apiInformation);
      const {service, v, method, path} = this.$route.query
      const methods = Object.keys(data.apis.main)
      const targetMethod = method || methods[this.firstTabIndex]

      if (!(service && v) || data.name !== service) {
        this.$router.push({
          query: {
            service: data.name,
            v: data.version.value,
            method: targetMethod
          }
        })

      }
      if (path) {
        const pathInfo = data.apis.main[targetMethod][path]
        if (pathInfo) {
          this.selectedUrlRow.push({
            url: path,
            info: pathInfo
          })
        }
      }

      const methodIndex = methods.indexOf(targetMethod)
      this.activeTab = (methodIndex !== -1) ? methodIndex : this.firstTabIndex

      if (data.swagger && data.swagger.trim() !== '') {
        this.drawerInfo.iframHeight = window.innerHeight;
      }
    },

    openAnalytics(serviceName, version, versionInfo) {

      const token = this.$store.getters['auth/getToken'];
      if (versionInfo.stats) {
        const myURL = versionInfo.stats + '?Authorization=' + token.access_token;
        window.open(myURL, '_blank');
      }

    },

    startDrawer(data, title) {
      this.bottomDrawer = true;
      this.drawertitle = title;
      this.drawerInfo = data;
    },

    closeDrawer() {
      if (Object.keys(this.$route.query).length) {
        this.$router.replace({query: {}})
      }
      this.bottomDrawer = false;
      this.drawertitle = null;
      this.drawerInfo = '';
    },

    formatUrls(apis) {
      return Object.entries(apis).map(([url, info]) => {
        return {url: url, info: info}
      })
    },

    urlItemsPerPage(apis) {
      return Object.keys(apis).length || 0
    },

    rowClick: function (item, row) {
      row.select(true);
      this.$router.push({
        query: {
          ...this.$route.query,
          path: item.url
        }
      })
    },

    async maintenanceOpC(type, oneAPI, method, route, serviceName, version) {
      const env = this.envSelected.value;
      this.dialog.show = false;
      this.dialog.loading = true;
      const op = await this.maintenanceReq(env, serviceName, version, {route: route, method: method})
      if (op.item && op.item.data) {
        if (typeof op.item.data === 'boolean') {
          setTimeout(() => {
            this.snackbar.show = true
            this.snackbar.color = 'success'
            this.snackbar.text = `${serviceName.toUpperCase()} maintenance Operation completed`
          });
        } else {
          this.dialog.show = true;
          this.dialog.width = 800;
          this.dialog.header = oneAPI.label;
          this.dialog.body = op.item.data;
          this.dialog.loading = false;
        }
      } else {

        this.dialog.body = '';
        this.dialog.loading = false;
      }
    },

    maintenanceReq(env, serviceName, version, info) {
      return this.getSendData({
        url: `/consoleapi/environments/${env}/api-catalog/operation`,
        method: 'post',
        params: {
          name: serviceName,
          version: version,
          operation: info.route,
          method: info.method
        }
      });
    },

    async maintenanceOp(type, serviceName, version, info, event) {
      const env = this.envSelected.value
      const op = await this.maintenanceReq(env, serviceName, version, info)

      switch (type) {
        case "heartbeat":
          this.handleHeartbeat(op, event, serviceName)
          break;
        case "reloadRegistry":
          this.handleReloadRegistry(op, event, serviceName)
          break;
      }
    },

    handleHeartbeat(op, event, serviceName) {
      const self = this;
      if (op.item && op.item.data) {
        const entries = Object.entries(op.item.data)
        const reloaded = entries
            .map(([_, v]) => v.heartbeat)
            .filter((h) => h)

        if (entries.length === reloaded.length) {
          healthy(event, serviceName)
        } else {
          notHealthy(event, serviceName)
        }
      } else {
        notHealthy(event, serviceName)
      }

      function healthy(event, serviceName) {
        self.snackbar.show = true
        self.snackbar.color = 'success'
        self.snackbar.text = `${serviceName.toUpperCase()}  is  healthy`
        self.blink(event, '#4caf50')
      }

      function notHealthy(event, serviceName) {
        self.snackbar.show = true
        self.snackbar.color = 'error'
        self.snackbar.text = `${serviceName.toUpperCase()}  is  NOT  healthy`
        self.blink(event, '#ec0909')
      }
    },

    handleReloadRegistry(op, event, serviceName) {
      const self = this;
      if (op.item && op.item.result) {
        reloaded(event, serviceName)
      } else {
        notReloaded(event, serviceName)
      }

      function reloaded(event, serviceName) {
        self.snackbar.show = true
        self.snackbar.color = 'orange'
        self.snackbar.text = `${serviceName.toUpperCase()} registry reloaded`
        self.blink(event, '#4caf50')
      }

      function notReloaded(event, serviceName) {
        self.snackbar.show = true
        self.snackbar.color = 'error'
        self.snackbar.text = `${serviceName.toUpperCase()} registry didn't reload`
        self.blink(event, '#ec0909')
      }
    },

    blink(event, endColor, transitionColor = 'orange', times = 2) {
      if (times !== 0) {
        event.target.style.color = transitionColor
        setTimeout(() => {
          event.target.style.color = endColor
          setTimeout(() => this.blink(event, endColor, transitionColor, times - 1), this.blinkTimeout)
        }, this.blinkTimeout)
      }
    },

    enableUpdate(serviceName, version) {
      const _self = this;
      _self.services[serviceName][version].updateService = true;

      if (this.$refs[`update-service-${serviceName}-${version}`]) {
        blink(_self.$refs[`update-service-${serviceName}-${version}`][0], true);
      }

      function blink(el, doBlink, times = 3) {
        _self.services[serviceName][version].blink = doBlink ? 'success' : 'grey';
        _self.$forceUpdate();
        if (times >= 0) {
          setTimeout(() => {
            blink(el, !doBlink, times - 1);
          }, _self.blinkTimeout);
        }
      }
    },

    doUpdateService(versionInfo) {
      const _self = this;
      if (confirm(`Are you sure you want to update the service and host weight values of '${versionInfo.name} / v${versionInfo.version.value}' ?`)) {
        //make api call
        let hosts = [];
        versionInfo.host.forEach((oneHost) => {
          hosts.push({
            ip: oneHost.ip,
            port: oneHost.port,
            weight: parseInt(oneHost.weight, 10)
          });
        });

        _self.getSendData({
          noLoading: true,
          url: `/consoleapi/environments/${this.envSelected.value}/api-catalog/service`,
          method: "post",
          params: {
            service: versionInfo.name,
            version: versionInfo.version.value,
            weight: parseInt(versionInfo.weight, 10),
            hosts
          }
        }).then(() => {
          _self.pushMessage({
            type: 'success',
            title: `Service Updated`,
            text: `The Weight Round Robin Distribution of service ${versionInfo.name} / v${versionInfo.version.value} has been updated.`
          });
          _self.scrollToTop();
        });
      }
    },

    copyToMyClipboard(data) {
      if (data && data.text) {
        let text = JSON.parse(data.text);
        document.getElementById('maintenance_api_response').innerText = JSON.stringify(text, null, 2);
        this.copyTextToClipboard('maintenance_api_response');
      }
    }
  },

  async created() {
    setTimeout(() => {
      this.initialize();
    }, 1800);
  },

  beforeDestroy() {
    this.closeDrawer();
  }
}
</script>

<style lang="scss">
.bottom-sheet-overlay {
  position: relative;
  z-index: 1000000;

  .card {
    margin-left: 290px !important;
    /*max-width:81.9% !important;;*/

  }
}

#logDisplayerCol {
  max-height: 800px;
  overflow: scroll;
}

.button {
  color: #4caf50;
  margin-bottom: 2px;
  padding: 5px;
}

.blink {
  background: red;
}

tr.v-data-table__selected {
  background: #dbdeea !important;
}

.custom_op {
  background-color: #121212;
  color: #4caf50 !important;
  font-weight: bold !important;
}

.custom_op span {
  color: papayawhip !important;
  font-weight: normal !important;
}

</style>
